<template>
	<div>
		<div class="veadmin-iframe-header veadmin-iframe-header-custom-fixed">
			<span>当前位置:</span>
			企业形象<span>&gt;</span>发展历程<span>&gt;</span>
			更新时间线
			<button class="veadmin-iframe-button" onclick="window.location.href = '/pc/admin/timeline/list';">
				<i class="el-icon-back"></i>
			</button>
			<button class="veadmin-iframe-button" onclick="location.reload()">
				<i class="el-icon-refresh-right"></i>
			</button>
		</div>
		<div class="veadmin-iframe-content veadmin-iframe-content-custom-fixed">
			<el-form :model="timeline" status-icon :rules="rules" label-width="100px" class="elForm">
				<el-form-item label="时间戳" prop="timestamp">
					<el-date-picker v-model="timeline.timestamp" value-format="yyyy-MM-dd" type="date" placeholder="选择日期"> </el-date-picker>
				</el-form-item>
				<el-form-item label="正文">
					<div id="wangEditor"></div>
				</el-form-item>
				<el-form-item label="备注" prop="note">
					<el-input type="textarea" v-model="timeline.note" placeholder="请输入内容"></el-input>
				</el-form-item>
				<el-form-item label="权重" prop="weight">
					<el-input type="number" v-model.number="timeline.weight" style="width:200px;" placeholder="权重"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" round @click="submitForm()">保存</el-button>
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>

<script>
// 引入 wangEditor
import wangEditor from "wangeditor";
import { apiGetTimelineById, apiUpdateTimeline } from "@/api/admin/timeline.js";

export default {
	data() {
		var checkEmpty = (rule, value, callback) => {
			if (!value) {
				return callback(new Error("不能为空"));
			} else {
				callback();
			}
		};
		return {
			editor: null,
			timeline: {
				title: "",
				text: "",
				timestamp: "",
				weight: 0
			},
			rules: {
				timestamp: [{ validator: checkEmpty, trigger: "blur" }],
				weight: [{ validator: checkEmpty, trigger: "blur" }]
			}
		};
	},
	methods: {
		async getTimelineById() {
			try {
				const response = await apiGetTimelineById(this.$store.state.token, this.$route.query.id);
				//console.log(response);
				if (response.data.code == 200) {
					this.timeline = response.data.data;
					this.editor.txt.html(this.timeline.text);
				} else {
					console.log("response>>", response);
					this.$message.error(response.data.message);
				}
			} catch (error) {
				console.log(error);
			}
		},
		submitForm() {
			//console.log(this.timeline);
			var formData = new FormData();
			formData.append("id", this.timeline.id);
			formData.append("text", this.timeline.text);
			formData.append("note", this.timeline.note);
			formData.append("timestamp", this.timeline.timestamp);
			formData.append("weight", this.timeline.weight);
			/* for (let [a, b] of formData.entries()) {
				console.log(a + ":", b || '""');
			} */
			//更新时间线
			apiUpdateTimeline(this.$store.state.token, formData).then((res) => {
				if (res.data.code === 200) {
					this.$message({ message: "成功保存", type: "success" });
					//执行成功1秒后跳转页面
					setTimeout(function() {
						window.location.href = "/pc/admin/timeline/list";
					}, 1000);
				} else if (response.data.code == -100) {
					this.$confirm(response.data.message + "。将跳转至登录页面, 是否继续?", "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "error"
					})
						.then(() => {
							//跳转到登录页面（路由跳转则滑动验证会失效）
							//this.$router.push({ path: "/login?target=" + window.location.href });
							window.location.href = "/login?target=" + window.location.href;
						})
						.catch(() => {
							this.$message({
								type: "warning",
								message: "已取消"
							});
						});
				} else {
					console.log("response>>", response);
					this.$message.error(response.data.message);
				}
			});
		}
	},
	mounted() {
		//页面渲染完成调用方法获取数据

		//生成编辑器
		const editor = new wangEditor("#wangEditor");
		//设置z-index
		editor.config.zIndex = 500;
		//默认编辑器占高300px,设置为320
		editor.config.height = 320;
		// 默认情况下，显示所有菜单
		editor.config.menus = [
			"head", //标题
			"bold", //加粗
			"indent", //缩进
			"list", //序列
			"underline", //下划线
			"strikeThrough", //删除线
			"splitLine", //分割线
			"table", //表格
			"emoticon", //表情
			"code", //代码
			"quote", //引用
			"link", //链接
			"undo", //撤销
			"redo" //重做
		];

		// 配置 onchange 回调函数，将数据同步到 vue 中
		editor.config.onchange = (newHtml) => {
			//console.log(newHtml);
			this.timeline.text = newHtml;
		};
		// 创建编辑器
		editor.create();
		this.editor = editor;

		this.getTimelineById();
	},
	beforeDestroy() {
		// 调用销毁 API 对当前编辑器实例进行销毁
		this.editor.destroy();
		this.editor = null;
	}
};
</script>

<style lang="scss" scoped>
.elForm {
	margin-top: 10px;
	margin-right: 20px;
}
</style>
